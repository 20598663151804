/* CardLoader */
.CardLoader{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-gap: 10px;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.CardLoader span{
  position: relative;
  width: 100%;
  background: linear-gradient(70deg, #D1E0E0, #F9FBFB);
  background-size: 400% 400%;
  animation: gradientAnimation 2s ease infinite;
  -webkit-animation: gradientAnimation 2s ease infinite;
  -moz-animation: gradientAnimation 2s ease infinite;
 display: block;
 border-radius: 30px;
  
}
@keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}
@-webkit-keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

@-moz-keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.CardLoader span:nth-child(1){
  height: 60%;
}
.CardLoader span:nth-child(2){
  height: 40px;
  width: 70%;
}
.CardLoader span:nth-child(3){
  height: 20px;
  width: 50%;
}

/* Navbar_Wrapper */

.Navbar_Wrapper{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  min-height: 50px;
}


/* NaVbar */

.NaVbar{
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1500;
  display: block;
  color: #373737;
}

.Top_Nav{
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #fff;
}
.Top_Nav .site-container{
  height: 100%;
}
.Top_Nav .Nav_Content{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  grid-gap: 20px;
  z-index: 1500;
}

.site-logo img{
  max-width: 90px;
  position: relative;
}

.Nav_Icons{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  grid-gap: 10px;
  margin-left: 20px;
}
.Nav_Toggler{
  display: none;
}
@media screen and (max-width:1200px){
  .Top_Nav{
    height: 60px !important;
  }
  .Nav_Icons{
    position: fixed !important;
    top: 60px !important;
    width: 300px !important;
    height: 100% !important;
    right: -100% !important;
    background-color: #fff;
    margin-left: 0px !important;
    z-index: 1000;
    border-top: 1px solid #E4E4E4 !important;
    display: block !important;
    overflow-y: auto;
    padding:0px 25px !important;
    transition: all 0.3s ease-in-out !important;
  }
  .active-Navbar .Nav_Icons{
    right: 0% !important;
  }
  .Top_Nav::before{
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 60px;
    left: 0;
    z-index: 200;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    -webkit-animation: FadeBbs 0.3s ease forwards;
    -moz-animation: FadeBbs 0.3s ease forwards;
    animation: FadeBbs 0.3s ease forwards;
    display: none;
  }
  .active-Navbar .Top_Nav::before{
    display: block !important;
  }
  @keyframes FadeBbs{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  @-webkit-keyframes FadeBbs{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  @-moz-keyframes FadeBbs{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  .Nav_Icons ul{
    position: relative;
    width: 100% !important;
    display: block !important;
  }
  .Nav_Icons ul li{
    position: relative;
    width: 100% !important;
    margin-top: 10px;
  }

  .Nav_Icons ul li a{
    width: 100% !important;
    position: relative;
    padding: 5px 0px !important;
  }
  .Nav_Icons ul li a img.Arrow_Down_Icons{
    transform: rotate(-90deg) !important;
  }
  .Nav_Icons ul.Ll_Nav_Ul li a{
    justify-content: space-between !important;
  }
  .Nav_Icons ul li.explore-Cc-Icon{
    display: none !important;
  }
  .Nav_Icons ul li.Mobile-Nav-Icon{
    display: block !important;
  }
  .Nav_Icons ul li.Mobile-Nav-Icon a img{
    width: 16px;
  }

  .Nav_Icons ul li.Mobile-Nav-Icon p img{
    width: 10px !important;
    margin: 0px !important;
  }
  .Nav_Icons ul li a.arts_training_Btn{
    background-color: transparent !important;
    border-color: transparent !important;
  }



  .Nav_Toggler{
    position: relative;
    display: inline-flex !important;
    width: 20px;
    height: 30px;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
  .Nav_Toggler span{
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #111827;
    transition: all 0.3s ease-in-out;
  }
  .Nav_Toggler span:first-child{
    transform: translateY(-7px);
  }
  .Nav_Toggler span:last-child{
    transform: translateY(7px);
  }

  .active-Navbar  .Nav_Toggler span:first-child{
    transform: rotate(45deg);
  }
  .active-Navbar  .Nav_Toggler span:last-child{
    transform: rotate(-45deg);
  }
  .active-Navbar  .Nav_Toggler span:nth-child(2){
    opacity: 0;
  }


  .Nav_Icons ul li{
    transform: translateY(10px);
  opacity: 0;
  -webkit-animation: FadeInTOp 0.5s ease forwards;
  -moz-animation: FadeInTOp 0.5s ease forwards;
  animation: FadeInTOp 0.5s ease forwards;
  }
}

@media screen and (max-width:500px){
  .Nav_Icons{
    width: 100% !important;
  }
}

@media screen and (max-width:500px){
  .site-logo img{
    max-width: 75px !important;
  }

  .Nav_Icons ul.Rr_Nav_Ul li a{
    width: 100% !important;
  }
}

@media screen and (max-width:400px){
  .Nav_Icons ul li a{
    font-size: 14px !important;
  }
  .Nav_Icons ul li a img{
    width: 14px !important;
  }
}
.Nav_Icons ul{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}
.Nav_Icons ul li{
  position: relative;
  z-index: 100;
}
.Nav_Icons ul li.Mobile-Nav-Icon{
  display: none;
}
.Nav_Icons ul li:hover .Courses_DropDown{
  display: block !important;
 }
.Nav_Icons ul li a{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  grid-gap: 7px;
  font-size: 0.875rem;
  padding: 8px 10px;
  border:1px solid transparent;
  border-radius: 40px;
  transition: all 0.3s ease-in-out;
  color: #535768 !important;
  border: 1px solid transparent;
  white-space: nowrap;
}

.Nav_Icons ul.Rr_Nav_Ul li .uL_login_btn{
  border-color: #0055FF !important;
  color: #0055FF !important;
  padding: 10px 15px !important;
  justify-content: center !important;
  text-align: center !important;
}
.Nav_Icons ul li a:hover{
  opacity: 0.8;
}
.Nav_Icons ul li a.arts_training_Btn{
  border-color: #B3C2BC;
  background-color: #F0F5FF;
  border-radius: 40px;
}

.Nav_Icons ul li a.signup_btn{
  background-color: #0055FF;
  color: #fff !important;
  padding: 10px 15px !important;
  justify-content: center !important;
  text-align: center !important;
}

.explore-Cc-Icon a img{
  position: relative;
}
.Nav_Icons ul li a img.Arrow_Down_Icons{
  width: 14px;
}
.active-explore-Cc.explore-Cc-Icon a img{
  transform: rotate(180deg);
}

.Rr_Sec_D{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  grid-gap: 15px;
}
.lang_Div{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  cursor: pointer;
  user-select: none;
}
.lang_Div span{
  position: relative;
  width: 50px;
  height: 40px;
  border:1px solid #B3C2BC;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:500px){
  .lang_Div span{
    width: 40px !important;
    height: 30px !important;
  }
  .lang_Div span img{
    width: 40% !important;
  }
  .lang_Div{
    gap: 7px !important;
    grid-gap: 7px !important;
  }
  .lang_Div p{
    padding-left: 7px !important;
    font-size: 12px !important;
  }
}
.lang_Div p{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  padding-left: 10px;
   border-left: 1px solid #373737;
}

.lang_DropDown{
  position: absolute;
  width:100%;
  height: auto;
  top: 100%;
  z-index: 10;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  transform: translateY(10px);
  opacity: 0;
  -webkit-animation: FadeInTOp 0.3s ease forwards;
  -moz-animation: FadeInTOp 0.3s ease forwards;
  animation: FadeInTOp 0.3s ease forwards;
  display: none;
  overflow: hidden;
}
.lang_Div:hover .lang_DropDown{
  display: block;
}
@keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@-moz-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

.lang_DropDown li{
  position: relative;
  width: 100%;
  height: auto;
  padding:8px 10px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.lang_DropDown li:hover{
  background-color: #DDE6E8;
}

/* Sub_Nav */
.Sub_Nav{
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 62px;
  background-color: #F3F6F7;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
@media screen and (max-width:1100px){
  .Sub_Nav{
    position: relative !important;
  }
}
.site-container{
  height: 100%;
}

.Sub_Nav_Content{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubNav_L{
  position: relative;
  height: 100%;
}
@media screen and (max-width:1100px){
  .SubNav_L{
    display: none !important;
  }
}
.SubNav_L ul.SsL_Ull{
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.SubNav_L ul.SsL_Ull li{
  position: relative;
  height: 100%;
}
.SubNav_L ul.SsL_Ull li a{
  position: relative;
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  gap: 7px;
  grid-gap: 7px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border-right: 1px solid #E4E4E4;
}
.SubNav_L ul.SsL_Ull li a img{
  width: 9px !important;
  opacity: 0.8;
}
.SubNav_L ul.SsL_Ull li a:hover{
  background-color: #DDE6E8;
}

.SubNav_L ul.SsL_Ull li a.Ssshs-Icon{
  background-color: #18BFFF;
  color: #000;
}
.SubNav_L ul.SsL_Ull li a.healthcare-Icon img{
  opacity: 1;
}
.SubNav_L ul.SsL_Ull li a.healthcare-Icon:hover{
  background-color: #00B1F4;
}
.Courses_DropDown{
  position: absolute;
  width: 250px !important;
  height: auto !important;
  top: 100%;
  display:none;
  z-index: 10;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  transform: translateY(10px);
  opacity: 0;
  -webkit-animation: FadeInTOp 0.3s ease forwards;
  -moz-animation: FadeInTOp 0.3s ease forwards;
  animation: FadeInTOp 0.3s ease forwards;
  overflow: hidden;
}
@media screen and (max-width:1200px){
  .Courses_DropDown{
    display: none !important;
  }
}
.Courses_DropDown li{
  display: block !important;
  width: 100% !important;
}
.Courses_DropDown li a{
  border:none !important;
  font-size: 13px !important;
  font-weight: normal !important;
  display: block !important;
  width: 100% !important;
  color: #5f6368 !important;
}
.Courses_DropDown li a:hover{
  color: #262B25 !important;
}
.SubNav_L ul li:hover .Courses_DropDown{
 display: block !important;
}

.SubNav_R{
  position: relative;
  width: auto;
  height: auto;
}
@media screen and (max-width:1100px){
  .Search_Sec input,
.Search_Sec,
  .SubNav_R{
    width: 100% !important;
  }
}
.Search_Sec{
  position: relative;
  width: auto;
  display: inline-flex;
  align-items: center;
}
.Search_Sec input{
  position: relative;
  width: 300px;
  height: 35px;
  border-radius: 5px;
  background-color: #fff;
  border:1px solid #B3C2BC;
  padding: 0px 10px;
  padding-right: 43px;
  font-size: 14px;
}
.Search_Sec button{
  position: absolute;
  width: 40px;
  height: 30px;
  z-index: 10;
  right: 1px;
  border-radius:3px;
  cursor: pointer;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.Search_Sec button img{
  width: 50%;
}
.Search_Sec button:hover{
  background-color: #F3F6F7;
}

.Search_Sec input:focus{
  border-color: #0055FF;
  border-width: 2px;
}

.hide-sub-navbar{
  display: none !important;
}




/* header-sec */


.header-sec{
  position: relative;
  width: 100%;
  height: auto;
  padding: 60px 0px;
  background-color: #111827;
  color: #fff;
  background-image: url(./assets/Img/header-bg.png);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}
.main-header{
  position: relative;
  width: 100%;
  height: auto;
}
.header-hero{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (max-width:1100px){
  .header-sec{
    background: #111827 !important;
  }
  .header-hero{
    display: flex !important;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 50px !important;
    grid-gap: 50px !important;
  }
  .hero_Banner{
    display: flex !important;
    justify-content: center;
  }
 .hero-foot{
    display: none !important;
  }
  .mobile-hero-foot{
    display: flex !important;
    margin-top: 40px !important;
    flex-direction: column !important;
    justify-content: center !important;
    text-align: center;
    gap: 20px !important;
    grid-gap: 20px !important;
  }

  .hero_Txt h3{
    justify-content: center !important;
    margin-bottom: 30px !important;
  }
  .Main_Course_Search{
    width: 100% !important;
  }
}

.hero_Txt{
  position: relative;
  width: 100%;
  height: auto;
}
.hero_Txt h3{
  position: relative;
  margin-bottom: 50px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  color: rgba(255,255,255,0.8);
  font-weight: 500;
  user-select: none;
}
.hero_Txt p{
  color: rgba(255,255,255,0.8) !important;
}
@media screen and (max-width:400px){
  .hero_Txt h3{
    display: none !important;
  }
}
.hero_Txt h3 span.courses-span{
  background: linear-gradient(to right, #1D91E8, #F82B60,#18BFFF, #FCB400);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.hero_Txt h3 a{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}
.hero_Txt h3 a span{
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 50px;
  height: 24px;
  background-color: rgb(242, 243, 246,0.4);
  -webkit-box-shadow: rgba(20, 23, 31, 0.1) 0px 2px 8px 0px inset;
  -moz-box-shadow: rgba(20, 23, 31, 0.1) 0px 2px 8px 0px inset;
  box-shadow: rgba(20, 23, 31, 0.1) 0px 2px 8px 0px inset;
  border:1px solid rgb(242, 243, 246,0.5);
  opacity: 1;
  border-radius: 30px;
}
.hero_Txt h3 a span::before{
  position: absolute;
  width: 20px;
  height: 20px;
  content: '';
  transform: translateY(-50%);
  top: 50%;
  left: 4px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(143, 111, 219, 0.34) 0px 3px 5px 0px, rgba(83, 193, 222, 0.34) 0px 3px 5px 0px;
  -webkit-box-shadow: rgba(143, 111, 219, 0.34) 0px 3px 5px 0px, rgba(83, 193, 222, 0.34) 0px 3px 5px 0px;
  -moz-box-shadow: rgba(143, 111, 219, 0.34) 0px 3px 5px 0px, rgba(83, 193, 222, 0.34) 0px 3px 5px 0px;
    opacity: 1;
}
.hero_Txt h1{
  font-weight: 600;
  margin-bottom: 20px;
}

.hero_Txt h1 span{
	font-family: "Sedan", serif;
  font-weight: 100;
  font-style: italic;
}


.Main_Course_Search{
  position: relative;
  width: 80%;
  height: auto;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}

.Main_Course_Search input{
  position: relative;
  width: 100%;
  height: 50px;
  border:1px solid #97ACD9;
  background-color: transparent;
  padding: 0px 15px;
  border-radius: 5px;
  font-size: 14px;
}
.Main_Course_Search input::placeholder{
  color: rgba(255,255,255,0.7);
}

.Main_Course_Search input:focus{
  border-color: #0055FF;
  border-width: 2px;
}

.Main_Course_Search button{
  position: relative;
  white-space: nowrap;
  height: 50px;
  padding: 0px 20px;
  border-radius: 5px;
  background-color: #0055FF;
  color: #fff !important;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.Main_Course_Search button:hover{
  background-color: #0048DD;
}

@media screen and (max-width:800px){
  .Main_Course_Search button{
    font-size: 14px !important;
  }
}
@media screen and (max-width:500px){
  .Main_Course_Search{
    flex-direction: column !important;
  }
  .Main_Course_Search button{
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  }
.hero_Banner{
  position: relative;
  width: 100%;
  height: auto;
}
.hero_Banner img{
  position: relative;
  max-width: 90%;
}

@media screen and (max-width:600px){
  .hero_Banner img{
    max-width: 100% !important;
  }
}
.hero-foot{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255,255,255,0.2);
  margin-top: 20px;
  padding-top: 10px;
}
.mobile-hero-foot{
  display: none;
}
.hero_Selt_Btns{
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}
@media screen and (max-width:500px){
  .hero-foot{
    display: none !important;
  }
}
.hero_Selt_Btns a{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  grid-gap: 5px;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 30px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.hero_Selt_Btns a:hover{
  background-color:#F0F5FF;
  color: #262B25 !important;
}
.hero_Selt_Btns a.free-icon {
  color: #fff !important;
  border-color: #0055FF;
  background-color: rgba(255,255,255,0.1);
}


.rec-Sec{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
}
@media screen and (max-width:499px){
  .rec-Sec{
    flex-direction: column !important;
    gap: 10px !important;
    grid-gap: 10px !important;
  }
}
.rec-Sec span{
  font-size: 14px;
  font-weight: 500;
}
.rec-Sec img{
  max-width: 100%;
}

/* Cllao_Sec */
.Cllao_Sec{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #18BFFF;
}

@media screen and (max-width:1100px){
  .Cllao_Sec{
    display: none !important;
  }
}

.Cllao_Sec ul{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px;
  padding: 30px 0px;
}
.Cllao_Sec ul li{
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}
.Cllao_Sec ul li img{
  width: 45px;
}
.Cllao_Sec ul li p{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
}
.Cllao_Sec ul li span{
  font-size: 14px;
}





/* Top-Courses-sec */

.Top-Courses-sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
}


/* site-Top-section */
.site-Top-section{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.top-Sec-Intro{
  position: relative;
  max-width: 700px;
}
@media screen and (max-width:800px){
  .top-Sec-Intro{
    max-width: 100% !important;
    width: 100% !important;
  }
}
.top-Sec-Intro p{
  display: block;
  margin-top: 15px;
}
.rend-btn{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 30px;
  grid-gap: 30px;
  margin-top: 30px;
}
.rend-btn li{
  position: relative;
  padding: 5px 0px;
  user-select: none;
  cursor: pointer;
  font-weight: 600;
  color: #5f6368;
  border-bottom: 3px solid transparent;
  font-size: 15px;
  white-space: nowrap;
}
@media screen and (max-width:500px){
  .rend-btn{
    width: 100% !important;
    overflow-x: auto !important;
  }
}
.rend-btn li.active-rend-btn{
  border-color: #0055FF;
  color: #0055FF;
}
.rend-btn li:hover{
  color: #0055FF;
}



/* Hh_courses */

.Hh_courses{
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px 0px;
  min-height: 300px;
  margin-top: 20px;
  border-radius: 10px;
  border:1px solid #E0E0E0;
}

@media screen and (max-width:700px){
  .Hh_courses{
    padding: 0px !important;
    border:none !important;
    border-bottom:1px solid #E0E0E0 !important;
    padding-bottom: 20px !important;
    margin-top: 30px !important;
    border-radius: 0px !important;
  }
  /* .Hh_Slider{
    margin-top: 20px !important;
  } */
}

.Hh_Box{
  position: relative;
  width: 100%;
  height: auto;
  display: none;
}

.active_Hh_Box{
  display: block;
}

.Hh_courses_Header{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  grid-gap: 10px;
}

@media screen and (max-width:1000px){
  .Hh_courses_Header{
    display: block !important;
  }
  .Hh_courses_Header p{
    margin-top: 5px !important;
  }
}


.Hh_Slider{
  position: relative;
  width: 100%;
  height: auto;
}
.Gga_Cardss {
  position: relative;
  width: 100%;
}


.slider-box {
  position: relative;
}

.slider-container{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (max-width:600px){
  .slider-container{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    grid-gap: 20px !important;
  }
}


.slick-next,
.slick-prev{
  width: 40px !important;
  height: 40px !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  padding-bottom: 30px;
  border:1px solid #E4E4E4 !important;
}

.slick-next{
  right: -50px !important;
}
.slick-prev{
  left: -50px !important;
}
.slick-prev:before, .slick-next:before {
  font-size: 23px !important;
  opacity: 1 !important;
  color: #262B25 !important;
  font-family: none !important;
  font-weight: 600 !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute !important;
}
@media screen and (max-width:700px){
  .slick-next{
    right: 0px !important;
  }
  .slick-prev{
    left: 0px !important;
  }
    .slick-next, .slick-prev{
    z-index: 10 !important;
    width: 35px !important;
    height: 35px !important;
  }
  .slick-prev:before, .slick-next:before {
    font-size: 18px !important;
  }
}



/* Hh_courses_Card */
.Hh_courses_Card{
  position: relative;
  width: 100%;
  height: auto;
  min-height: 350px;
  background-color: #fff;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 2%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 2%);
  -moz-box-shadow: 0 0 10px rgb(0 0 0 / 2%); */
  border-radius: 0px;
  z-index: 1;
  overflow: hidden;
}

.custom-next-arrow.hidden, .custom-prev-arrow.hidden {
  display: none !important;
}

.course_card{
  position: relative;
  width: 100%;
  display: block;
}

.course_card_banner{
  position: relative;
  width: 100%;
  height: auto;
}
.course_card_banner img{
  position: relative;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
@media screen and (max-width:1000px){
  .course_card_banner img{
    height: auto !important;
    max-height: 250px !important;
  }
}
@media screen and (max-width:600px){
  .course_card_banner img{
    height: auto !important;
    max-height: 100% !important;
  }
}

.course_card_dlt{
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px 0px;
}
@media screen and (max-width:500px){
  .course_card_dlt{
  }
  .course_card_dlt h3{
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }
}
.course_card_dlt h3{
  display: -webkit-box;
   line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 15px;
  font-weight: 500;
  color: #0055FF;
}
.course_card:hover .course_card_dlt h3{
  text-decoration: underline;
}
.course_card_dlt p{
  display: -webkit-box;
   line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.course_card_dlt span{
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  grid-gap: 5px;
  margin-top: 7px;
  font-size: 13px;
  /* color: #5f6368 !important; */
}
.course_card_dlt span img{
  width: 25px;
}



/* Part_Sec */
.Part_Sec{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 40px;
}
/* .Part_Sec h3{
  font-size: 20px;
  font-weight: 500;
} */

.Part_Sec ul{
  position: relative;
  margin-top: 30px;
}
.Part_Sec ul li{
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin:10px 10px;
  border:1px solid #C8C8C8;
  padding: 10px 0px;
  border-radius: 10px;
}
@media screen and (max-width:1000px){
  .Part_Sec ul{
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    gap: 10px !important;
    grid-gap: 10px !important;
  }
  .Part_Sec ul li{
    margin: 10px 0px !important;
  }
  .Part_Sec ul li img{
    width: 50px !important;
    height: 30px !important;
  }
  .Part_Sec ul li p{
    font-size: 10px !important;
    white-space: nowrap;
  }
}
@media screen and (max-width:520px){
  .Part_Sec ul{
    justify-content: flex-start !important;
  }
}
.Part_Sec ul li img{
  width: 60px;
  height: 40px;
  object-fit: contain;
  margin: 0px 20px;
}
.Part_Sec ul li p{
  position: relative;
  border-top: 1px solid #C8C8C8;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 10px;
  padding: 5px 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
/* Abt_Sec */
.Abt_Sec{
  position: relative;
  width: 100%;
  height: auto;
  padding: 80px;
  display: grid;
 grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

@media screen and (max-width:1100px){
  .Abt_Sec{
    display: flex !important;
    flex-direction: column;
    gap: 30px !important;
    grid-gap: 30px !important;
  }
}

@media screen and (max-width:800px){
  .Abt_Sec{
    padding: 50px 0px !important;
  }
}

.Abt_Dlt{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.Abt_Dlt h2{
  margin-bottom: 30px;
}
.Abt_Dlt ul{
  position: relative;
  padding-left: 20px;
  margin-top: 20px;
}
.Abt_Dlt ul li{
  position: relative;
  width: 100%;
  display: block;
  margin-top: 20px;
}
.Abt_Dlt ul li::before{
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #111827;
  left: -15px;
  top: 9px;
  border-radius: 50%;
}
.Abt_Dlt ul li p{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.Abt_Btns{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
}
.Abt_Dlt a{
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0055FF;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
@media screen and (max-width:500px) {
  .Abt_Dlt a{
    font-size: 14px !important;
  }
}
.Abt_Dlt a:hover{
  background-color: #0046D7;
}
.Abt_Dlt a.train_btn{
  background-color: #fff;
  border:1px solid #111827;
  color: #111827;
}
.Abt_Dlt a.train_btn:hover{
  background-color: #F3F6F7;
}
.Abt_Banner{
  position: relative;
  width: 100%;
  height: auto;
  display: block;
}
.Abt_Banner img{
  max-width: 100% !important;
}




.porpular_Sec{
  position: relative;
  width: 100%;
  height: auto;
}
.porpular_Sec .Hh_courses{
  background-color: transparent !important;
  /* padding: 0px !important; */
  border:none !important;
}
.porpular_Sec .Hh_Box{
  display: block !important;
}
.Carrt_Porls{
  margin-top: 0px !important;
}
.Carrt_Porls .Hh_courses{
  padding: 0px !important;
}

.courses_For_You .Hh_Box{
  display: block !important;
}



/* Maihs_sec */
.Maihs_sec{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #F3F6F7;
  margin-top: 50px;
}
.Glans_Bg{
  background-color: #111827;
  color: #fff;
}
.Maihs_sec .Abt_Sec{
  margin-top: 0px !important;
}
.Maihs_sec .Abt_Sec p{
  display: block !important;
  margin-top: 15px !important;
}

.Glans_Bg .Abt_Sec p{
  color: #fff !important;
}




/* site-footer */

.site-footer{
  position: relative;
  width: 100%;
  height: auto;
}

.footer-content{
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}
@media screen and (max-width:1000px){
  .footer-content{
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    grid-gap: 20px !important;
  }
}
@media screen and (max-width:700px){
  .footer-content{
    padding: 50px 0px !important;
  }
}
.foot-Card{
  position: relative;
  width: 100%;
  height: auto;
}

img.foot-logo{
  max-width: 90px !important;
}
.foot-Card h4{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.foot-Card p{
  display: block;
  margin: 30px 0px;
}

.foot-Card img{
  max-width: 100%;
}
.foot-Card{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
@media screen and (max-width:1000px){
  .foot-Card{
    display: block !important;
  }
}
.foot-Card:last-child{
  align-items: flex-end !important;
}

.foot-Card ul{
  position: relative;
  margin-top: 30px;
}

.foot-Card ul li{
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 15px;
}
.foot-Card ul li a:hover{
  text-decoration: underline;
}
.foot-Card:last-child div.SsLla-Div{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
}
@media screen and (max-width:1000px){
  .foot-Card:last-child div.SsLla-Div{
    flex-direction: row !important;
  }
}
@media screen and (max-width:450px){
  .foot-Card:last-child div.SsLla-Div{
    display: block !important;
    text-align: left !important;
  }
}
.SsLla-Div button{
  position: relative;
  width: auto;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  grid-gap: 7px;
  padding: 10px 20px;
  background-color: #F0F5FF;
  border:1px solid #B3C2BC;
  font-size: 16px;
  font-weight: 500;
}
.SsLla-Div button img{
  width: 16px;
}
.social-icon{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width:450px){
  .social-icon{
    justify-content: center !important;
    width: 100% !important;
    padding-top: 15px !important;
    margin-top: 20px !important;
    border-top: 1px solid #CFCFCF !important;
  }
  .SsLla-Div h6{
    text-align: center !important;
  }
}
.social-icon a{
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.social-icon a:hover{
  opacity: 0.8;
}
.SsLla-Div h6{
  font-size: 13px;
  font-weight: normal;
}


/* courses-page */
.courses-page{
  position: relative;
  width: 100%;
  height: auto;
}

.navigateTo-Top{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
}
@media screen and (max-width:1000px){
  .navigateTo-Top{
    display: none !important;
  }
}
.navigateTo-Top ul{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  grid-gap: 15px;
  height: 50px;
  font-size: 13px;
}
.navigateTo-Top ul li img{
  width: 6px;
}
.navigateTo-Top ul li a{
  color: #0055FF;
}
.navigateTo-Top ul li a:hover{
  text-decoration: underline;
}


.All_Courses_Sec{
  position: relative;
  width: 100%;
  height: auto;
}
@media screen and (max-width:1000px){
  .All_Courses_Sec{
    margin-top: 15px !important;
  }
}

.All_Course_Main{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
}

@media screen and (max-width:1100px){
  .All_Course_Main{
    display: block !important;
  }
  .filter_Sec{
    position:  relative !important;
    top: 0px !important;
    width: 100% !important;
    margin-bottom: 40px !important;
    min-height: 0px !important;
  }
  .filter_Sec ul{
    display: none !important;
  }
  .filter-box{
    padding: 20px !important;
  }
}

.filter_Sec{
  position: sticky;
  top: 130px;
  width: 300px;
  min-height: 300px;
  z-index: 100;
}

.filter-box{
  position:inherit;
  top: inherit;
  z-index: inherit;
  width: 100%;
  height: auto;
  padding: 30px;
  background-color: #F3F6F7;
  border:1px solid #E4E4E4;
  border-radius: 10px;
}

.filter-box h3{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.filter-box ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.filter-box ul p{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.filter-box ul li{
  position: relative;
  margin-top: 12px;
}
.custom-checkbox {
  appearance: none;
  width: 13px;
  height: 13px;
  background-color: transparent;
  border: 1px solid #79839a;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.custom-checkbox:checked {
  background-color: #0055FF;
  border-color: #0055FF;
}

.custom-checkbox:checked::before {
  content: "\2714"; /* Checkmark symbol */
  display: block;
  color: white;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
}

.filter-box ul li label{
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  gap: 10px;
  grid-gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

/* Courses_Groups */
.Courses_Groups{
  position: relative;
  width: 100%;
  height: auto;
}

.Topp_Seck{
  position: relative;
  width: 700px;
  height: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
}
@media screen and (max-width:800px){
  .Topp_Seck{
    width: 100% !important;
  }
  .Topp_Seck_Img img{
    width: 200px !important;
  }
  .Topp_Seck_Txt h3{
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width:700px){
  .Topp_Seck{
    display: block !important;
  }
  .Topp_Seck_Img{
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .Topp_Seck_Img img{
    width: 100% !important;
    height: auto !important;
    max-height: 300px !important;
  }
}
.Topp_Seck_Img{
  position: relative;
  width: auto;
  height: auto;
}

.Topp_Seck_Img img{
  position: relative;
  width: 250px;
  max-height: 220px;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width:400px){
  .Topp_Seck_Img img{
    width: 100% !important;
  }
}
.Topp_Seck_Txt{
  position: relative;
  width: 100%;
  height: auto;
 
}
.Topp_Seck_Txt h3{
  margin-bottom: 30px;
}
.Topp_Seck_Txt span{
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 7px;
  grid-gap: 7px;
  color: #5f6368;
}
.Topp_Seck_Txt span img{
  width: 25px;
}


.CCc_Box{
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px;
  border:1px solid #E0E0E0;
  border-radius: 10px;
  margin-top: 20px;
}
@media screen and (max-width:600px){
  .CCc_Box{
    padding:20px 0px !important;
    border:none !important;
    border-radius: 0px !important;
  }
}
.CCc_Box_Top{
  position: relative;
  width: 100%;
  height: auto;
}
.CCc_Box_Top h4{
  font-size: 16px;
  font-weight: 500;
  color: #383838;
}

.CCc_Card{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #F3F6F7;
  border:1px solid #E4E4E4;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
}
@media screen and (max-width:600px){
  .CCc_Card{
    display: block !important;
  }
}
.CCc_Card_1{
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px;
}

.CCc_Card_1_Top{
  position: relative;
  width: 100%;
  height: auto;
}
.CCc_Card_1_Top h3{
  font-size: 16px;
  font-weight: 500;
}

.CCc_Card_1_Mid{
  position: relative;
  width: 100%;
  max-height: 150px;
  margin-top: 10px;
  overflow: hidden;
}
.show_more_Dlt .CCc_Card_1_Mid{
  max-height: 100% !important;
}
.Career-Divv{
  position: relative;
  width: 100%;
  height: auto;
  display: none;
}
.show_more_Dlt .CCc_Card_1_Mid .Career-Divv{
  display: block !important;
}
.CCc_Card_1_Mid h4{
  font-size: 15px;
  font-weight: 500;
  margin: 15px 0px;
}
.CCc_Card_1_Mid p{
  display: block;
  margin-top: 15px;
  font-size: 14px;
  color: #383838 !important;
  line-height: 170%;
}
.CCc_Card_1_Mid ul{
  position: relative;
  padding:10px 25px;
}
.CCc_Card_1_Mid ul li{
  list-style: disc !important;
  margin-top: 10px;
  font-size: 14px;
  color: #383838 !important;
  line-height: 150%;
}

.CCc_Card_1_Foot{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.CCc_Card_1_Foot button{
  position: relative;
  padding: 10px;
  background-color: transparent;
  color: #0055FF;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  grid-gap: 5px;
  font-size: 14px;
}

.CCc_Card_2{
  position: relative;
  width: 200px;
  height: 100%;
  padding: 20px;
  border-left:1px solid #E4E4E4;
}
@media screen and (max-width:600px){
  .CCc_Card_2{
    border-left:none !important;
    border-top:1px solid #E4E4E4 !important;
    width: 100% !important;
  }
}
.CCc_Card_2 ul{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-gap: 10px;
}

.CCc_Card_2 ul li{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  grid-gap: 7px;
}
.CCc_Card_2 ul li span{
  text-transform: uppercase;
  font-size: 13px;
}
.CCc_Card_2 ul li img{
  width: 14px;
}
.CCc_Card_2 ul h3{
  font-size: 25px;
  font-weight: 600;
  color: #0055FF;
}
.CCc_Card_2 ul button{
  position: relative;
  width: 100%;
  height: auto;
  padding:13px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0055FF;
  color: #fff;
  border-radius: 5px;
  gap: 7px;
  grid-gap: 7px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.CCc_Card_2 ul button:hover{
  background-color: #0048DD;
}

.DaNns_Footd{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.DaNns_Footd_1{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DaNns_Footd_1 a{
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 20px;
  border:1px solid #111827;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.DaNns_Footd_1 a:hover{
  background-color: #F0F5FF;
}
@media screen and (max-width:500px){
  .DaNns_Footd_1 a{
    padding: 15px !important;
    font-size: 13px !important;
  }
  .DaNns_Footd{
    margin-top: 10px !important;
  }
}
.DaNns_Footd_2{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
}
@media screen and (max-width:1000px){
  .DaNns_Footd_2{
    display: none !important;
  }
}
.DaNns_Footd_2 ul{
  position: relative;
  width: 100%;
  height: auto;
}
.DaNns_Footd_2 ul li{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
  margin-top: 5px;
}
.DaNns_Footd_2 ul li p{
  font-size: 14px;
  font-weight: 500;
}
.DaNns_Footd_2 ul li span a{
  color: #0055FF;
  display: inline-flex;
  align-items: center;
  margin: 0px 10px;
  font-size: 13px;
}
.DaNns_Footd_2 ul li span a:hover{
  text-decoration: underline;
}

.payment_DropDown{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 20px;
  grid-gap: 20px;
}
.show_payment_DropDown.payment_DropDown{
  display: flex !important;
}
.payment_DropDown::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -100;
  background-color: rgba(0,0,0,0.8);
  opacity: 0;
  -webkit-animation: SimFadeIn 0.3s ease forwards;
  -moz-animation: SimFadeIn 0.3s ease forwards;
  animation: SimFadeIn 0.3s ease forwards;
}
@keyframes SimFadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-webkit-keyframes SimFadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-moz-keyframes SimFadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.payment_Box{
  position: relative;
  width: 500px;
  height: auto;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  transform: translateY(10px);
  opacity: 0;
  -webkit-animation: FadeInTOp 0.3s ease forwards;
  -moz-animation: FadeInTOp 0.3s ease forwards;
  animation: FadeInTOp 0.3s ease forwards;
}

@media screen and (max-width:600px){
  .payment_Box{
    width: 90% !important;
  }
}

.close_payment_DropDown{
  position: absolute;
  right: 10%;
  top: 40px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border:1px solid rgba(255,255,255,0.4);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.close_payment_DropDown:hover{
  background-color: rgba(255,255,255,0.1);
}
.close_payment_DropDown::before{
  content: '';
  position: absolute;
  width: 70%;
  height: 1px;
  background-color: #fff;
  transform: rotate(45deg);
}
.close_payment_DropDown::after{
  content: '';
  position: absolute;
  width: 70%;
  height: 1px;
  background-color: #fff;
  transform: rotate(-45deg);
}



/* About_Sec_Hh */
.About_Sec_Hh{
  padding: 150px 0px !important;
  background-image:linear-gradient(rgba(17,24,39,0.5),rgba(17,24,39,0.9)),url(./assets//Img//abbout-header-bg.jpg) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
@media screen and (max-width:600px){
  .About_Sec_Hh{
    padding: 100px 0px !important;
  }
}
.About_Sec_Hh  .header-hero{
  display:flex !important;
  text-align: center !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 0px !important;
  grid-gap: 0px !important;
}
.About_Sec_Hh .hero_Txt{
  max-width: 800px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}
.About_Sec_Hh .hero_Txt p{
  color: #fff !important;
  line-height: 180% !important;
  font-weight: normal !important;
}

.About_Sec_Hh .Main_Course_Search{
  display: inline-flex !important;
}



/* training_HeroO */
.training_HeroO{
  margin-top: 0px !important;
}
.training_HeroO .Abt_Banner img{
  max-width: 100% !important;
}


.Gbagb_Sec{
  margin-top: 0px !important;
}


.Missi_Viss_Sec{
  position: relative;
  width: 100%;
  height: auto;
  padding: 60px 0px;
}

.Missi_Viss_Sec h2{
  text-align: center;
}

.Mmai_grid{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px;
  margin-top: 60px;
}
@media screen and (max-width:1000px){
  .Mmai_grid{
    grid-gap: 30px !important;
  }
}
@media screen and (max-width:900px){
  .Mmai_grid{
    display: flex !important;
    flex-direction: column !important;
    gap:30px !important;
  }
}
.Mmai_card{
  position: relative;
  width: 100%;
  height: auto;
}
.Mmai_card img{
  position: relative;
  max-width: 100%;
  margin-bottom: 10px;
}
.Mmai_card h3{
  font-size: 20px;
  font-weight: 600;
}
.Mmai_card p b{
  color: #111827 !important;
}




/* Lla_Contact_sec */

.Lla_Contact_sec{
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 0px;
}
.Lla_Contact_Main{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (max-width:1100px) {
  .Lla_Contact_sec{
    padding: 30px 0px !important;
  }
  .Lla_Contact_Main{
    display: flex !important;
    flex-direction: column !important;
  }
  .Lla_Contact_L img{
    width: 100% !important;
    height: 350px !important;
    object-fit: cover !important;
    object-position: top center !important;
  }
}
@media screen and (max-width:500px) {
  .Lla_Contact_L img{
    height: auto !important;
  }
}
.Lla_Contact_L{
  position: relative;
  width: 100%;
  height: auto;
}
.Lla_Contact_L img{
  position: relative;
  max-width: 100%;
}

.Lla_Contact_R{
  position: relative;
  width: 100%;
  height: auto;
}

.Lla_Contact_R h3{
  font-size: 20px;
  font-weight: 600;
}

.Cont_Form{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.Cont_Form_Inpt{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 15px;
}
.Cont_Form_Inpt textarea,
.Cont_Form_Inpt input{
  position: relative;
  width: 100%;
  height: 50px;
  border:1px solid #C0C5CE;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 15px;
  resize: none;
}
.Cont_Form_Inpt textarea:focus,
.Cont_Form_Inpt input:focus{
  border-color: #0055FF;
  border-width: 2px;
}
.Cont_Form_Inpt textarea{
  height: 150px;
}
.Cont_Form_Inpt button{
  position: relative;
  width: 200px;
  height: 50px;
  padding: 0px 10px;
  background-color: #0055FF;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.Cont_Form_Inpt button:hover{
  background-color: #0048DD;
}
@media screen and (max-width:300px) {
  .Cont_Form_Inpt button{
    width: 100% !important;
  }
}
.Dd_Flex{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
}
@media screen and (max-width:450px) {
  .Dd_Flex{
    display: block !important;
  }
}
.Cont_DLts{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #F3F6F7;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
}
.Cont_DLts .site-container{
  display: flex;
  justify-content: center;
}
.Cont_DLts ul{
  position: relative;
  width: 60%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width:700px) {
  .Cont_DLts ul{
    display: block !important;
  }
}
@media screen and (max-width:1300px) {
  .Cont_DLts ul{
    width: 100% !important;
    padding: 0px !important;
  }
  .Cont_DLts ul li{
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid #E4E4E4 !important;
    width: 100% !important;
  }
  .Cont_DLts ul li:first-child{
    border-top: none !important;
  }
}
.Cont_DLts ul li{
  position: relative;
  width: 100%;
  height: auto;
  border-left: 1px solid #E4E4E4;
  padding: 30px;
}
@media screen and (max-width:900px) {
  .Cont_DLts ul li{
    padding: 30px 20px !important;
  }
}
.Cont_DLts ul li:last-child{
  border-right: 1px solid #E4E4E4;
}
.Cont_DLts ul li img{
  position: relative;
  margin-bottom: 20px;
  max-width: 100%;
}
.Cont_DLts ul li p{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}


/* Hha_Secks */

.Hha_Secks{
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px 0px;
}
.Hha_Secks a{
  color: #0055FF !important;
  text-decoration: underline !important;
}

.Hha_Secks h2{
  margin-bottom: 30px;
}
.Hha_Secks p{
  display: block;
  margin-top: 10px;
}
.Hha_Secks ol li{
  list-style: decimal;
  margin-top: 15px;
}
.Hha_Secks ul{
  padding: 20px !important;
}

.Hha_Secks ul li{
  list-style: disc;
  margin-top: 15px;
}

.Policy p{
  font-size: 16px !important;
  line-height: 160%;
  color: #5f6368 !important;
  margin-top: 10px;
}

@media screen and (max-width:700px){
  .Policy p{
    font-size: 15px !important;
  }
}
@media screen and (max-width:450px){
  .Policy p{
    font-size: 14px !important;
  }
}

.Policy h3{
  font-size: 17px;
  font-weight: 600;
  margin: 10px 0px;
}














/* Reg_SecO */
.Reg_SecO{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr auto;
}
.Simp_Oosi{
  display: block !important;
}
.Reg_banner{
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111827;
  background-image: url(./assets/Img/reg-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Reg_Env{
  position: relative;
  width: 600px;
  height: auto;
  min-height: 100vh;
  padding-bottom: 40px;
}
.Simp_Oosi .Reg_Env{
  width: 100% !important;
}
@media screen and (max-width:1200px){
  .Reg_Env{
    width: 500px !important;
  }
}
@media screen and (max-width:1100px){
  .Reg_SecO{
    display: block !important;
  }
  .Reg_banner{
    display: none !important;
  }
  .Reg_Env{
    width: 100% !important;
  }
}

.Top_Nav_l{
  width: 100%;
  height: 70px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  justify-content:space-between;
}
@media screen and (max-width:500px){
  .Top_Nav_l{
    padding: 0px 30px !important;
  }
}

@media screen and (max-width:400px){
  .Reg_Tt_NAva .Rr_Sec_D .lang_Div{
    display: none !important;
  }
}

.Top_Nav_l_main{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  grid-gap: 15px;
}

.Top_Nav_l_main ul.Rr_Nav_Ul{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
}

.Top_Nav_l_main ul.Rr_Nav_Ul li a{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  grid-gap: 7px;
  font-size: 16px;
  padding: 5px 15px;
  border:1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width:500px){
  .Top_Nav_l_main ul.Rr_Nav_Ul li a{
    font-size: 15px !important;
  }
}
.Top_Nav_l_main ul.Rr_Nav_Ul li a:hover{
  opacity: 0.8;
}

.Top_Nav_l_main ul.Rr_Nav_Ul li a.signup_btn{
  background-color: #0055FF;
  color: #fff !important;
  font-weight: 500;
  padding: 8px 15px;
}


.Reg_Form_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.Simp_Oosi .Reg_Form_Sec{
  margin-top: 50px !important;
}

@media screen and (max-width:1100px){
  .Reg_Form_Sec{
    margin-top: 40px !important;
  }
}

.Reg_Form_Box{
  position: relative;
  width: 430px;
  height: auto;
}

@media screen and (max-width:600px){
  .Reg_Form_Box{
    width: 90% !important;
  }
}

.Reg_Header{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  grid-gap: 20px;
}

.Reg_Header h3{
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
}
@media screen and (max-width:300px){
  .Reg_Header h3{
    font-size: 20px !important;
  }
}
.Reg_Header button{
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  border:1px solid #111827;
  gap: 8px;
  grid-gap: 8px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}
@media screen and (max-width:300px){
  .Reg_Header button{
    font-size: 13px !important;
  }
  .Reg_Header button img{
    width: 25px !important;
  }
}
.Reg_Header button:hover{
  background-color: #F0F5FF;
}

.Reg_Header p{
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #E2E2E2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.Reg_Header p span{
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  color: #7B7B7B;
  font-weight: 500;
  font-size: 15px;
}

.Reg_Form{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.form-group,
.Reg_Input{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 15px;
}
.login_btn,
.form-group button,
.form-group input,
.Reg_Input button,
.Reg_Input input{
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0px 12px;
  border:1px solid #AEAEAE;
  border-radius: 8px;
  font-size: 14px;
}
.form-group input::placeholder,
.Reg_Input input::placeholder{
  color: #727272;
}

.form-group input:focus,
.Reg_Input input:focus{
  border-color: #0055FF;
  border-width: 2px;
}

.password-wrapper,
.Reg_Pass{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.password-wrapper input,
.Reg_Pass input{
  padding-right: 40px;
}
.ShowHidePass{
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ShowHidePass img{
  width: 20px;
}

.Reg_Input a{
  color: #0055FF;
  font-size: 13px;
  white-space: nowrap;
}
.Reg_Input a:hover{
  text-decoration: underline;
}

.login_btn,
.Reg_Input button,
.Reg_Input input[type="submit"]{
  background-color: #0055FF;
  color: #fff;
  border-color: #0055FF;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.login_btn:hover,
.Reg_Input button:hover,
.Reg_Input input[type="submit"]:hover{
  background-color: #0048DD;
  border-color: #0048DD;
}
.Forget_pass,
.Reg_Input p{
  font-size: 14px !important;
  color: #5f6368 !important;
}
.Forget_pass a,
.Reg_Input p a{
  font-size: 14px !important;
}



















































/* HiDE_This */

.HiDE_This{
  display: none !important;
}

































































/* Cookie Consent Banner */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  color: white;
  padding: 15px;
  text-align: center;
  z-index: 9999;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  transform: translateY(10px);
  opacity: 0;
  -webkit-animation: FadeInTOp 0.3s ease forwards;
  -moz-animation: FadeInTOp 0.3s ease forwards;
  animation: FadeInTOp 0.3s ease forwards;
}

.cookie-consent-content p a{
  text-decoration: underline !important;
}

.cookie-consent-banner button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #0055FF;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 15px;
  border-radius: 4px;
  margin-top: 20px;
  border:1px solid transparent;
  transition: all 0.3s ease-in-out;
}
.cookie-consent-banner button:hover{
  opacity: 0.8;
}

.reject-btn{
  background-color: transparent !important;
  border-color: #fff !important;
}
.settings-btn{
  background-color: #fff !important;
  color: #000 !important;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-consent-banner {
    font-size: 14px;
    padding: 10px;
  }
  
  .cookie-consent-banner button {
    font-size: 14px;
    padding: 8px 16px;
    width: 45%;
  }
}

/* Cookie Settings Modal */
.cookie-settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cookie-settings-modal h2 {
  margin-top: 0;
  font-size: 24px;
}

.cookie-settings-modal button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.cookie-settings-modal button:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-settings-modal {
    width: 90%;
    padding: 15px;
  }
  
  .cookie-settings-modal h2 {
    font-size: 20px;
  }

  .cookie-settings-modal button {
    width: 100%;
    font-size: 14px;
  }
}



















































/* Cookie Settings Modal */
.cookie-settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cookie-settings-modal h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

.cookie-settings-modal p {
  font-size: 16px;
  margin-bottom: 20px;
  /* text-align: center; */
  margin-top: 10px;
}

.cookie-settings-modal button {
  padding: 10px 20px;
  background-color: #0055FF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 15px;
  display: block;
  width: 100%;
  margin: 10px 0;
  transition: all 0.3s ease-in-out;
}
.cookie-settings-modal button:hover{
  background-color: #0046D7 !important;
}

.cookie-settings-modal button.close-btn{
  color: #000 !important;
  background-color: #e0e0e0;
}
.cookie-settings-modal button.close-btn:hover{
  background-color: #ccc8c8 !important;
}
/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-settings-modal {
    width: 90%;
    padding: 15px;
  }

  .cookie-settings-modal h2 {
    font-size: 20px;
  }

  .cookie-settings-modal p {
    font-size: 14px;
  }

  .cookie-settings-modal button {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .cookie-settings-modal {
    width: 95%;
    padding: 10px;
  }

  .cookie-settings-modal h2 {
    font-size: 18px;
  }

  .cookie-settings-modal p {
    font-size: 12px;
  }

  .cookie-settings-modal button {
    font-size: 12px;
    padding: 8px 16px;
  }
}


.cookie-category label input{
  margin-right: 10px;
}
.cookie-category label span{
  display: flex;
  align-items: center;
}














































/*hero-testimonial*/

.hero-testimonial{
	position: relative;
	width: auto;
	height: auto;
	display: inline-flex;
	align-items: center;
	grid-gap: 20px;
	margin-top: 40px;
}
@media screen and (max-width:600px){
.hero-testimonial{
	flex-direction: column !important;
}
.icon-btn{
	display: none !important;
}
}
.her-text-l{
	position: relative;
	width:auto;
	display: flex;
	align-items: center;
}
.her-text-l span{
	position: relative;
	width: 55px;
	height: 55px;
	background: #ffffff;
	border-radius: 50%;
	   -webkit-box-shadow: 0px 0px 20px rgb( 0 0 0 / 10%);
     -moz-box-shadow: 0px 0px 20px rgb( 0 0 0 / 10%);
      box-shadow: 0px 0px 20px rgb( 0 0 0 / 10%);
      border: 2px solid #ffffff;
}
.her-text-l span:nth-child(2){
	margin-left: -15px;
}

.her-text-l span:nth-child(3){
	margin-left: -15px;
}
.her-text-l span img{
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
	border-radius: inherit;
}
.her-text-r{
	position: relative;
	width:auto;
	height: auto;
}

.her-text-r h4{
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	margin: 0;
	padding: 0;
}
.her-text-r p{
	font-size: 14px;
	color: #565656;
	display: block;
	margin-top: -5px;
}
.icon-btn{
	position: relative;
	background: transparent;
	outline: none !important;
	border: none;
	font-weight: 700;
	font-size: 20px;
	color: #fff;
	transition: all 0.5s ease-in-out;
}

.icon-btn img{
	width: 25px;
}